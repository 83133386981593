import PropTypes from 'prop-types';
import React from 'react';

import useStaticProductCardData from 'hooks/useStaticProductCardData';
import ProductCardStaticPlaceholder from '../ProductCardStaticPlaceholder';
import ProductCardStatic from '../ProductCardStatic';

const HotspotProductCard = ({ productId }) => {
  const { loading, products: staticProductData } = useStaticProductCardData({
    productIds: [productId],
  });

  return (
    <div className="w-[150px] h-[200px]">
      {loading && <ProductCardStaticPlaceholder />}
      {!loading && !!staticProductData[0] && (
        <ProductCardStatic
          {...staticProductData[0]}
          layout="mini"
          isHotspotCard
        />
      )}
    </div>
  );
};

HotspotProductCard.propTypes = {
  fabricsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  isInContentfulPromotionHero: PropTypes.bool,
  productConfig: PropTypes.shape({
    fabric: PropTypes.string,
    leather: PropTypes.string,
  }),
  productId: PropTypes.number,
};

export default HotspotProductCard;
