import { useMemo } from 'react';

import { GET_PRODUCTS_STATIC_DATA } from 'mocks/queries';
import getVisitorLocationQueryVariables from 'commons/getVisitorLocationQueryVariables';
import useUserLocation from 'global-state/userLocation/useUserLocation';
import useProductListingQuery from 'hooks/useProductListingData/useProductListingQuery';
import useProductVariantsToShow from 'hooks/useProductListingData/useProductVariantsToShow';
import useProductListingWithFavorites from 'hooks/useProductListingData/useProductListingWithFavorites';
import useProductListingProductsOnDisplay from 'hooks/useProductListingData/useProductListingProductsOnDisplay';

const useStaticProductCardData = ({
  colorFilters,
  productIds = [],
  upholsteryLabelFilters,
  cacheKey,
  limit,
}) => {
  const userLocation = useUserLocation();
  const zipCode = userLocation?.zip ?? '';

  const { isInitialDataLoading, products } = useProductListingQuery(
    GET_PRODUCTS_STATIC_DATA,
    {
      variables: {
        cacheKey,
        productIds,
        zipCode,
        ...getVisitorLocationQueryVariables(userLocation?.stateShortName),
      },
      skip: productIds.length === 0,
      limit,
    }
  );

  const productsWithVariantsToShow = useProductVariantsToShow({
    colorFilters,
    products,
    upholsteryLabelFilters,
  });

  const {
    slug,
    productsOnDisplay,
  } = useProductListingProductsOnDisplay();

  const productsToShowWithShowroomSlug = useMemo(() =>
    productsWithVariantsToShow.map(product => ({
      ...product,
      onDisplayInShowroomSlug: productsOnDisplay.includes(product.id) ? slug : undefined
    })), [productsWithVariantsToShow, productsOnDisplay, slug]);

    const productsToShowWithFavorites = useProductListingWithFavorites({
      products: productsToShowWithShowroomSlug,
    });

  // Merge data together into an object that can be used as a context value
  const productListingPageData = useMemo(
    () => ({
      loading: isInitialDataLoading,
      products: productsToShowWithFavorites,
      productsOnDisplay,
    }),
    [
      isInitialDataLoading,
      productsOnDisplay,
      productsToShowWithFavorites,
    ]
  );

  return productListingPageData;
};

export default useStaticProductCardData;
