import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

import GenericLoader from 'components/essentials/GenericLoader';
import useContentfulPromotionContent from 'hooks/useContentfulPromotionContent';
import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import useScreen from 'hooks/useScreen';
import HotspotProductCard from '../hotspot-wrapper/HotspotProductCard';
import ImageWithTextBanner2 from '../image-with-text-banner2';

const defaultAdminConfig = {
  alignment: 'left',
  imageProps: {
    src:
      'https://joybird2.imgix.net/user-uploads-staging/JB0420-Banners-Homepage-Hero-Semi-Annual-Sale-1587138557709.jpeg',
    srcWide:
      'https://joybird2.imgix.net/user-uploads-staging/JB0420-Banners-Homepage-Hero-Semi-Annual-Sale-3x2-1587138600167.jpeg',
    altTitle: '',
    srcSquare:
      'https://joybird2.imgix.net/user-uploads-staging/JB0420-Banners-Homepage-Hero-Semi-Annual-Sale-1x1-1587138676231.jpeg',
    srcExtraWide:
      'https://joybird2.imgix.net/user-uploads-staging/JB0420-Banners-Homepage-Hero-Semi-Annual-Sale-2x1-1587138646941.jpeg',
  },
  colors: {
    textHex: '#ffffff',
    backgroundHex: '#016A78',
  },
  textContent: {
    icon:
      'https://joybird2.imgix.net/user-uploads-staging/Semi-Annual-Sale@3x-1587162162751.png',
    heroLogomark: '',
    body: '',
    title: 'Admin preview',
    disclaimer: 'Don’t miss out, sale ends 4/27.',
    backgroundImage:
      'https://joybird2.imgix.net/user-uploads-staging/joybird-teal-1587161610709.jpeg',
  },
  ctaColors: {
    textHex: '#ffffff',
    backgroundHex: '#2F2F2E',
  },
  'Image URL': {
    'Image Wide 3:2':
      'https://joybird2.imgix.net/user-uploads-staging/JB0120-Banners-Homepage-Hero-Desktop-Mid-Winter-Sale-3x2-(1)-1579751476637.jpeg',
    'Image Square 1:1':
      'https://joybird2.imgix.net/user-uploads-staging/JB0120-Banners-Homepage-Mid-Winter-Sale-1x1-1579751588094.jpeg',
    'Image Extra Wide 2:1':
      'https://joybird2.imgix.net/user-uploads-staging/JB0120-Banners-Homepage-Hero-Desktop-Mid-Winter-Sale-2x1-(1)-1579628289122.jpeg',
  },
  toggleInputType: {
    ctaLink: {
      style: 'solid-button',
      color: 'white',
      linkTo: '/shop',
      content: 'Shop Now',
    },
    selected: {
      value: 'CTA Link',
    },
    ctaButton: {
      type: 'CTA',
      style: 'solid-button',
      content: 'Button Content',
      embedCode: {
        value: '',
        enabled: false,
      },
    },
  },
};

export const NonSliderHero = ({
  isAdmin,
  loading,
  promotionData,
  inSlider = false,
}) => {
  const bannerRef = useRef();
  const { isMobile } = useScreen();

  let config = defaultAdminConfig;

  if (!isAdmin) {
    if (loading) {
      return (
        <div className="flex overflow-hidden flex-col items-stretch lg:h-[560px] [&_h1]:lg:max-w-[700px]">
          <GenericLoader inline />
        </div>
      );
    }

    if (!promotionData) {
      return null;
    }
    const {
      title,
      body,
      disclaimer,
      icon,
      backgroundImage,
      // backgroundColor, CON-1692 . Do not use background color here.
      heroTextBackgroundColor,
      heroFancyText,
      heroFancyTextColor,
      heroLogomark,
      mainTextColor,
      mediaAssetUltraWide,
      mediaAssetExtraWide,
      mediaAssetWide,
      mediaAssetSquare,
      linkUrl,
      ctaText,
      ctaLinkColor,
      ctaTextColor,
      ctaStyle,
      ctaBackgroundColor,
    } = promotionData;

    config = {
      showHotSpots: true,
      alignment: 'left',
      imageProps: {
        src: mediaAssetUltraWide,
        srcWide: mediaAssetWide,
        altTitle: ctaText,
        srcSquare: mediaAssetSquare,
        srcExtraWide: mediaAssetExtraWide,
      },
      colors: {
        textHex: mainTextColor,
        backgroundHex: backgroundImage
          ? 'transparent'
          : heroTextBackgroundColor,
      },
      textContent: {
        icon,
        heroFancyText,
        heroFancyTextColor,
        heroLogomark,
        body,
        title,
        disclaimer,
        backgroundImage,
      },
      ctaColors: {
        textHex: ctaTextColor,
        backgroundHex: ctaBackgroundColor,
      },
      toggleInputType: {
        ctaLink: {
          style: ctaStyle,
          color: ctaLinkColor,
          linkTo: linkUrl,
          content: ctaText,
        },
        selected: {
          value: 'CTA Link',
        },
        ctaButton: {
          type: 'CTA',
          style: 'solid-button',
          content: 'Button Content',
          embedCode: {
            value: '',
            enabled: false,
          },
        },
      },
    };
  }
  return (
    <div
      className={classNames(
        // String.raw is used here to allow the underscores to be properly
        // escaped for Tailwind, as documented here: https://github.com/tailwindlabs/tailwindcss/issues/8881#issuecomment-1208600668
        String.raw`[&_.\_\_react\_component\_tooltip]:p-0 [&_.\_\_react\_component\_tooltip]:opacity-100`,
        {
          'md:h-full': inSlider,
        }
      )}
      ref={bannerRef}
    >
      <ImageWithTextBanner2
        config={config}
        isHomepage
        isFullHeight={!!inSlider}
        isContentfulPromotionHero
      />
      {!isMobile ? (
        <ReactTooltip
          // Note: The hotspot-tooltip-wrapper class is required for the
          // HotspotRenderer component to target this correctly (since it is a
          // third party we cannot use a data attribute here)
          className="hotspot-tooltip-wrapper p-0 !absolute !opacity-100 [transition:all_.3s_ease-in-out] !text-gray-light8 !bg-gray-light8 hover:!text-[#eaeaeb] hover:![background:#eaeaeb]"
          scrollHide={false}
          type="light"
          effect="solid"
          event="none"
          clickable
          overridePosition={({ left, top }) => {
            const position = bannerRef.current.getBoundingClientRect();
            return {
              left,
              top: top - position.top + 100,
            };
          }}
          getContent={product_data => {
            if (!product_data) return null;
            const productDataSplits = product_data.split('?');
            const productId = parseInt(productDataSplits[0], 10);
            const productConfig = qs.parse(productDataSplits[1] || '');
            return (
              <div
                className="w-[150px] h-[235px]"
                key={`hotspot-tooltip-${productId}-${productDataSplits[1] ||
                  ''}`}
              >
                <HotspotProductCard
                  isInContentfulPromotionHero
                  productConfig={productConfig}
                  productId={productId}
                  showLabel={false}
                />
              </div>
            );
          }}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

NonSliderHero.propTypes = {
  inSlider: PropTypes.bool,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  promotionData: PropTypes.shape({
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    body: PropTypes.string,
    ctaBackgroundColor: PropTypes.string,
    ctaLinkColor: PropTypes.string,
    ctaStyle: PropTypes.string,
    ctaText: PropTypes.string,
    ctaTextColor: PropTypes.string,
    disclaimer: PropTypes.string,
    heroFancyText: PropTypes.string,
    heroFancyTextColor: PropTypes.string,
    heroTextBackgroundColor: PropTypes.string,
    heroLogomark: PropTypes.string,
    icon: PropTypes.string,
    linkUrl: PropTypes.string,
    mainTextColor: PropTypes.string,
    mediaAssetExtraWide: PropTypes.string,
    mediaAssetSquare: PropTypes.string,
    mediaAssetUltraWide: PropTypes.string,
    mediaAssetWide: PropTypes.string,
    title: PropTypes.string,
  }),
};

const ImageWithTextBanner2WithData = ({
  config: configProp = {},
  isAdmin = false,
}) => {
  const { loading, promotionData = {} } = useContentfulPromotionContent(
    configProp?.ContentfulEntry?.id,
    configProp?.ContentfulEntry?.carouselId
  );
  const postHogFlag = usePostHogFeatureFlag('shop-vs-sale-landing-page');
  const sliderPromotionData = cloneDeep(promotionData);

  if (
    configProp?.ContentfulEntry?.id === '4uNLGdUc11POI817Yo138i' &&
    postHogFlag === 'control'
  ) {
    sliderPromotionData.linkUrl = '/shop/';
  }

  return (
    <NonSliderHero
      isAdmin={isAdmin}
      loading={loading}
      promotionData={sliderPromotionData}
    />
  );
};

ImageWithTextBanner2WithData.propTypes = {
  config: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default ImageWithTextBanner2WithData;
