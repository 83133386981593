import React from 'react';
import { Picture } from 'react-imgix';
import PropTypes from 'prop-types';

const JBResponsiveImage = ({ children }) => (
  <Picture>
    {children}
    <img
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      className="hidden h-0 w-0"
      alt=""
    />
  </Picture>
);

JBResponsiveImage.propTypes = {
  children: PropTypes.node,
};

export default JBResponsiveImage;
