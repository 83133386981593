import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import JBImage from 'components/essentials/JBImage';
import JBResponsiveImage from 'components/essentials/JBResponsiveImage';
import BannerTextSection from './BannerTextSection';

import definition from './definition.yaml';

const ImageWithTextBanner2 = ({
  config = {},
  isFullHeight = false,
  isHomepage = false,
}) => {
  const [isBannerImageLoaded, setIsBannerImageLoaded] = useState(false);

  const onImageLoad = useCallback(() => {
    setIsBannerImageLoaded(true);
  }, []);

  const {
    imageProps,
    alignment = 'left',
    textContent,
    colors,
    ctaColors = {},
    toggleInputType,
    cta,
  } = config;

  const colorsBackgroundHex =
    colors?.backgroundHex ?? definition.props.colors.backgroundHex;

  const imageAltTitle =
    imageProps?.altTitle ?? definition.props.imageProps.altTitle;

  const imageClassName =
    'block h-[50vw] object-cover w-full lg:h-full lg:w-full';

  const imageSrcUltraWide = imageProps?.src ?? definition.props.imageProps.src;

  // The Ultra Wide image above is the primary crop - if any of the other sizes
  // are not provided then they fall back to the Ultra Wide version
  const imageSrcExtraWide = imageProps?.srcExtraWide ?? imageSrcUltraWide;
  const imageSrcSquare = imageProps?.srcSquare ?? imageSrcUltraWide;
  const imageSrcWide = imageProps?.srcWide ?? imageSrcUltraWide;

  return (
    <div
      className={cx(
        'flex overflow-hidden flex-col items-stretch lg:h-[560px] [&_h1]:lg:max-w-[700px] 2xl:h-full 2xl:items-center',
        {
          '!bg-white': !isBannerImageLoaded,
          'lg:flex-row-reverse': alignment === 'left',
          'lg:flex-row': alignment === 'right',
          'md:h-full lg:h-full': isFullHeight,
        }
      )}
      style={{
        backgroundColor: colorsBackgroundHex,
      }}
    >
      <div className="relative flex-1 lg:h-full lg:w-full">
        <JBResponsiveImage>
          {!!imageSrcExtraWide && (
            <JBImage
              alt={imageAltTitle}
              ar="2:1"
              auto="format,compress"
              className={imageClassName}
              crop="center"
              lazy={false}
              media="(max-width: 1023px)"
              onLoad={onImageLoad}
              renderAsSource
              sizes="100vw"
              src={imageSrcExtraWide}
            />
          )}

          {!!imageSrcSquare && (
            <JBImage
              alt={imageAltTitle}
              ar="1:1"
              auto="format,compress"
              className={imageClassName}
              crop="center"
              lazy={false}
              media="(min-width: 1024px) and (max-width: 1151px)"
              onLoad={onImageLoad}
              renderAsSource
              sizes="50vw"
              src={imageSrcSquare}
            />
          )}

          {!!imageSrcWide && (
            <JBImage
              alt={imageAltTitle}
              ar="3:2"
              auto="format,compress"
              className={imageClassName}
              lazy={false}
              media="(min-width: 1152px) and (max-width: 1440px)"
              onLoad={onImageLoad}
              renderAsSource
              sizes="60vw"
              src={imageSrcWide}
            />
          )}

          {!!imageSrcExtraWide && (
            <JBImage
              alt={imageAltTitle}
              ar="2:1"
              auto="format,compress"
              className={imageClassName}
              lazy={false}
              media="(min-width: 1441px) and (max-width: 1799px)"
              onLoad={onImageLoad}
              renderAsSource
              sizes="68vw"
              src={imageSrcExtraWide}
            />
          )}

          {!!imageSrcUltraWide && (
            <JBImage
              alt={imageAltTitle}
              auto="format,compress"
              className={imageClassName}
              lazy={false}
              media="(min-width: 1800px)"
              onLoad={onImageLoad}
              renderAsSource
              sizes="75vw"
              src={imageSrcUltraWide}
            />
          )}

          {!!imageSrcWide && (
            <JBImage
              alt={imageAltTitle}
              auto="format,compress"
              className={imageClassName}
              lazy={false}
              onLoad={onImageLoad}
              sizes="60vw"
              src={imageSrcWide}
              width={1}
            />
          )}
        </JBResponsiveImage>
      </div>

      <BannerTextSection
        config={{ textContent, colors, cta, ctaColors, toggleInputType }}
        isHomepage={isHomepage}
        className="2xl:!h-[revert] 2xl:!self-stretch"
      />
    </div>
  );
};

ImageWithTextBanner2.propTypes = {
  config: PropTypes.shape({
    colors: PropTypes.object,
    cta: PropTypes.object,
    ctaColors: PropTypes.object,
    textContent: PropTypes.object,
    toggleInputType: PropTypes.object,
  }),
  isFullHeight: PropTypes.bool,
  isHomepage: PropTypes.bool,
};

export default React.memo(ImageWithTextBanner2);
